import "App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "layout";
import ModalManager from "modal";
import { useEffect, useState } from "react";
import { api } from "services";

function App() {
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.getSSOMeta().then(() => {
      setIsLoading(false);
    }).catch(() => {
      setTimeout(() => {
        setTrigger(!trigger)
      }, 5000)
    });
  }, [trigger])

  if (isLoading) return <></>

  return (
    <div>
      <Router basename="/">
        <ModalManager />
        <Routes>
          <Route path="/*" element={<Layout />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
